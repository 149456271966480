<template>
  <div>
    <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refFinancialCommissionsTable'].refresh()"
    >
      <template #table>
        <b-table
            ref="refFinancialCommissionsTable"
            no-border-collapse
            class="position-relative"
            :fields="visibleFields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="searchAgentsCommissions"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :filter="searchInput"
            :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template
              #cell(amount_for_sup)="data"
              class="p-0"
          >
            $ {{ data.item.amount_for_sup | currency }}
          </template>
          <template
              #cell(commission_type)="data"
              class="p-0"
          >
            <b-badge
                :variant="getVariantForBadge(data.item.commission_type_id)"
                class="w-100"
            >
              <span>{{ data.item.commission_type }}</span>
            </b-badge>
          </template>
          <template #custom-foot>
            <b-tr class="bg-dark ">
              <b-th>
                <div style="margin: 0.5rem" />
                <span v-if="!isAgent"
                      class="font-medium-1"
                >Total</span>
              </b-th>

              <b-th />
              <b-th />
              <b-th v-if="!isAgent" class="footer-dark">
                <div class="footer-span w-100">
                  <span class="font-small-3 ">$ {{
                      sub_amount_agent | currency
                    }} </span>
                </div>
              </b-th>
              <b-th
                  v-if="!isAgent"
                  class="footer-dark"
              >
                <div class="footer-span w-100">
                  <span class=" font-small-3 ">$ {{
                      sub_amount_for_supervisor | currency
                    }} </span>
                </div>
              </b-th>
              <b-th
                  v-if="!isAgent && !isSupervisor && !isAssistantSupervisor"
                  class="footer-dark"
              >
                <div class="footer-span w-100">
                  <span class=" font-small-3  ">$ {{
                      sub_amount_for_assist | currency
                    }} </span>
                </div>
                <br>
                <div class="footer-span w-100">
                  <span class=" font-small-3  ">$ {{
                      amount_for_assist | currency
                    }} </span>
                </div>
              </b-th>
              <b-th />

            </b-tr>
          </template>

          <template
              #cell(created_at)="data"
              class="p-0"
          >
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import financialCommissionsFilters from '@/views/ce-digital/sub-modules/financial/view/commissions/data/financial-commissions-filters'
import FinancialCommissionsService from '@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service'
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import { mapGetters } from 'vuex'
import CedDashboardTracking
  from '@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/service/ced-dashboard-tracking'
import moment from 'moment'

export default {
  data() {
    return {
      filters: financialCommissionsFilters,
      fields: [
        {
          key: 'agent_name',
          sortable: false,
          label: 'User',
          visible: true,
        },
        {
          key: 'commission_type',
          sortable: false,
          label: 'Commission Type',
          visible: true,
        },
        {
          key: 'account',
          sortable: false,
          label: 'Client account',
          visible: true,
        },
        {
          key: 'amount',
          sortable: false,
          label: 'Amount for user',
          formatter: val => `$ ${val}`,
          visible: true,
        },
        {
          key: 'amount_for_sup',
          sortable: false,
          label: 'Amount for Supervisor',
          formatter: val => `$ ${val}`,
          visible: true,
        },
        {
          key: 'amount_for_assist_sup',
          sortable: false,
          label: 'Amount for Assistant Supervisor',
          formatter: val => `$ ${val}`,
          visible: true,
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Created at',
          visible: true,
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'created_at',
      sortDesc: true,
      searchInput: '',
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by client account...',
        model: '',
      },
      itemsFinancialCommissions: [],
      months: [
        { id: 1, value: 'January' },
        { id: 2, value: 'February' },
        { id: 3, value: 'March' },
        { id: 4, value: 'April' },
        { id: 5, value: 'May' },
        { id: 6, value: 'June' },
        { id: 7, value: 'July' },
        { id: 8, value: 'August' },
        { id: 9, value: 'September' },
        { id: 10, value: 'October' },
        { id: 11, value: 'November' },
        { id: 12, value: 'December' },
      ],
      sub_amount_agent: 0,
      amount_agent: 0,
      sub_amount_for_supervisor: 0,
      amount_for_supervisor: 0,
      sub_amount_for_assist: 0,
      amount_for_assist: 0,
      addSmsModalOn: false,
      date: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    visibleFields() {
      return this.fields.filter(column => column.visible)
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.setUpFiltersData()
    this.fields[4].label = this.isAssistantSupervisor ? 'Amount for Supervisor' : 'Amount for Assistant Supervisor'
    this.fields[4].visible = !this.isAgent
    this.fields[3].visible = !this.isAgent
    this.fields[5].visible = !this.isAgent && !this.isSupervisor && !this.isAssistantSupervisor
    this.filters[4].visible = false
    this.date = new Date()
    // this.filters[2].model = this.date.getFullYear()
    // this.filters[3].model = this.date.getMonth() + 1
    // await this.$refs.refFinancialCommissionsTable.refresh()
  },
  methods: {
    openSmsModal() {
      this.addSmsModalOn = true
    },
    async searchAgentsCommissions(ctx) {
      const parameters = {
        text: this.filterPrincipal.model,
        date_from: this.filters[0].model,
        date_to: this.filters[1].model,
        year: this.filters[2].model,
        month: this.filters[3].model,
        agent_id: this.currentUser.user_id,
        type_of_commission: this.filters[5].model,
        per_page: this.paginate.perPage,
        role_id: this.currentUser.role_id,
        page: this.paginate.currentPage,
        orderby: ctx.sortBy ? ctx.sortBy : 'created_at',
        user_id: this.currentUser.user_id,
        order: ctx.sortDesc ? 'desc' : 'asc',
        module_id: this.moduleId,
      }
      let data = ''
      data = await FinancialCommissionsService.getCedAgentsCommissions(parameters)
      this.itemsFinancialCommissions = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      if (this.itemsFinancialCommissions.length > 0) {
        this.amount_agent = this.itemsFinancialCommissions[0].am_for_agent
        this.amount_for_assist = this.itemsFinancialCommissions[0].am_for_assist_sup
        this.amount_for_supervisor = this.itemsFinancialCommissions[0].am_for_sup
        this.sub_amount_agent = this.itemsFinancialCommissions[0].sub_am_for_agent
        this.sub_amount_for_assist = this.itemsFinancialCommissions[0].sub_am_for_assist_sup
        this.sub_amount_for_supervisor = this.itemsFinancialCommissions[0].sub_am_for_sup
      } else {
        this.sub_amount_agent = 0
        this.sub_amount_for_assist = 0
        this.sub_amount_for_supervisor = 0
      }
      return this.itemsFinancialCommissions || []
    },
    async setUpFiltersData() {
      // agents
      const dataAgents = await CedDashboardTracking.getSubModuleAgents({
        sub_module_id: this.moduleId,
      })
      if (dataAgents.status === 200) {
        this.filters[4].options = dataAgents.data
      }
      // years
      this.filters[2].options = []
      for (let x = 2022; x <= moment().format('YYYY'); x++) {
        this.filters[2].options.push({ id: x, value: x })
      }
      // months
      this.filters[3].options = this.months
      // commission types
      const dataCommissionTypes = await CeSetCommissionsService.getCedCommissionTypes({
        module_id: this.moduleId,
      })
      this.filters[5].options = dataCommissionTypes.data
    },
    getVariantForBadge(type) {
      switch (type) {
        case 10:
          return 'primary'
        case 11:
          return 'warning'
        case 12:
          return 'info'
        case 13:
          return 'success'
        case 14:
          return 'light-warning'
        default:
          return 'danger'
      }
    },
  },
}
</script>

<style scoped>

</style>
