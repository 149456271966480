<template>
  <div>
    <header-slot/>
      <b-tabs
          lazy
          pills
          nav-class="mb-0"
          active-nav-item-class="bg-primary box-shadow-info"
      >
        <b-tab title="User Commissions"  :title-link-class="[bgTabsNavs, 'px-3']">
          <table-financial-my-commissions />
        </b-tab>
        <b-tab  lazy title="Commissions from department" :title-link-class="[bgTabsNavs, 'px-3']" >
          <table-financial-commissions />
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>

import TableFinancialCommissions from "@/views/ce-digital/sub-modules/financial/view/commissions/components/TableFinancialCommissions.vue";
import TableFinancialMyCommissions from "@/views/ce-digital/sub-modules/financial/view/commissions/components/TableFinancialMyCommissions.vue";
export default {
  components: {
    TableFinancialCommissions,
    TableFinancialMyCommissions
  },
  data() {
    return {
      tabIndex: 1,
      tabTitle: '',
    }
  },
  created() {
    this.tabTitle = this.isAssistantSupervisor || this.isSupervisor || this.isChief ? 'Commissions from department ': 'My Commissions'
  },
};
</script>