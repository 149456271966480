export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: false,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: false,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Year',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
    disabled: false,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Month',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
    disabled: false,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Agent',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'full_name',
    cols: 12,
    disabled: false,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Type of Commission',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 12,
    visible: true,
  },
]
